class NavigationSchnelleinstiegKachelnTabs extends HTMLElement {
  
  connectedCallback() {
    this.activeTab = 0;
    this.globaleVariablenDeklarieren();
    this.toggleActiveTab(null, this.activeTab, false);
    this.handleTabs();
  }

  globaleVariablenDeklarieren() {
      this.tabs = this.querySelectorAll(".tab-item");
      this.sliderElement = this.querySelectorAll("slider-element");
  }

  toggleActiveTab(oldVal, newVal, shouldSlide) {
    if (oldVal === newVal) {
      return;
    }

    this.setTabStates(oldVal, newVal);
    this.activeTab = newVal;
  }

  setTabStates(oldVal, newVal) {
    this.tabs[newVal].setAttribute('active', '');
    this.sliderElement[newVal].setAttribute('data-status', 'visible');

    if (oldVal !== null) {
      this.tabs[oldVal].removeAttribute('active');
      this.sliderElement[oldVal].setAttribute('data-status', 'hidden');
    }
  }

  handleTabs() {
    this.tabs.forEach((tab, index) => {
      tab.addEventListener("click", () => {
        this.toggleActiveTab(this.activeTab, index, true);
      });
    });
  }

}

if (!customElements.get("navigation-schnelleinstieg-kacheln-tabs")) {
  customElements.define("navigation-schnelleinstieg-kacheln-tabs", NavigationSchnelleinstiegKachelnTabs);
}
